import React from 'react';
import { Accordion } from '../Accordion';
import { DocumentToReactComponents } from './DocumentToReactComponents';

interface LogoContentAccordion {
  internalName?: string;
  heading?: string;
  subHeading?: string;
  headingBackgroundColor?: string;
  content?: Document;
  logos?: LogoImage[];
}

interface LogoContentAccordionProps {
  logoContentProps: LogoContentAccordion;
  referenceId:string;
}

interface LogoImage {
  url?: string;
  text?: string;
}

export const LogosOrContentAccordion = ({ logoContentProps,referenceId }: LogoContentAccordionProps) => {
  return (
    <>
      <div className="lg:mx-20 md:mx-5 mx-4 pb-5" id={referenceId}>
        <Accordion
          title={logoContentProps?.heading!}
          bgGradient={logoContentProps?.headingBackgroundColor}
          active={logoContentProps?.logos && true}>
          <>
            {logoContentProps?.logos && (
              <div className="card-body grid lg:grid-cols-10 grid-cols-4">
                {logoContentProps?.logos?.map((logos: any) => {
                  return (
                    <>
                      <img className="p-2" src={logos.url} alt="channel logo" width="80" height="70" />
                    </>
                  );
                })}
              </div>
            )}
            {logoContentProps?.content && (
              <div className="px-12 py-4 sky-bg-gray-lighter">
                <DocumentToReactComponents richtextEditorProps={logoContentProps?.content as any} />
              </div>
            )}
          </>
        </Accordion>
      </div>
    </>
  );
};
